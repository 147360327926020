<template>
  <div class="container">
    <div class="head">
      <HeaderView :bgColor="bgColor"></HeaderView>
    </div>
    <div class="banner">
      <div class="banner_text">
        <h1>六六仓储</h1>
        <p>打造中国数字化钢铁工业产业链生态圈</p>
      </div>
    </div>
    <div class="module_title wow animate__fadeInUp" data-wow-duration="2s">
      项目全景图
      <div class="line wow animate__fadeInUp" data-wow-duration="2s"></div>
    </div>
    <div class="productAll wow animate__fadeInUp" data-wow-duration="2s">
      <img src="../assets/images/home/productAll.png" alt="">
    </div>
    <div class="module_title wow animate__fadeInUp" data-wow-duration="2s">
      核心产品
      <div class="line wow animate__fadeInUp" data-wow-duration="2s"></div>
    </div>
    <ul class="core_product wow animate__fadeInUp" data-wow-duration="2s">
      <li>
        <h1>仓储平台</h1>
        <p>TORAGE PLATFORM</p>
        <div class="li_cover">
          <h1>仓储平台</h1>
          <p>TORAGE PLATFORM</p>
          <p>智慧仓储平台：主要针对园区、仓库、港口码头等仓储业务的数字化管理，可实现多种仓库（钢材仓库、散货仓库、平堆托盘仓库、货架仓库、堆垛机仓库等），多货主、多品种、多业务模式、多结算方式的数字化仓储管理；可结合物联网技术，实现全流程、智能化、可视化管理，提高客户服务能力及支撑产 业链延伸、资源整合。</p>
        </div>
      </li>
      <li>
        <h1>供应链金融平台</h1>
        <p>SUPPLY CHAIN FINANCE PLATFORM</p>
        <div class="li_cover">
          <h1>供应链金融平台</h1>
          <p>TORAGE PLATFORM</p>
          <p>实现基于【淘钢网物联网监管仓】的在线金融服务平台，支持仓单融资，也支持代理采购代理销售线上化的供应链服务；实现融资过程的是数字化、物联网化的风控管理，同时实现多角色的在线协同管理，包括：融资方、监管单位、仓储方、资金方、担保公司及供应链企业的在线化协同管理。</p>
        </div>
      </li>
      <li>
        <h1>物联网监管平台</h1>
        <p> INTERNET OF THINGS REGULATORY PLATFORM</p>
        <div class="li_cover">
          <h1>物联网监管平台</h1>
          <p>INTERNET OF THINGS REGULATORY PLATFORM</p>
          <p>淘钢网旗下六六仓监管平台主要为监管单位和银行提供一套在线化、数字化的监管业务系统，系统釆集仓储系统数据、企业ERP 数据及物联网数据进行多数据源的交叉验证及建立数字化风控模型。监管企业：实现日常监管业务管理；包括项目管理、每日盘点、出入库登记、事件登记、仓单管理及多种物联网数据查询、数字化风控模型、风险管控等功能；银行端：实现和加强在线监管、自动监管能力。其功能包括：在线设置和管理押品、押品价格、最低控货线、仓单质押、风 险设置、预警查询等功能。</p>
        </div>
      </li>
      <li>
        <h1>供应链管理平台</h1>
        <p>SUPPLY CHAIN MANAGEMENT PLATFORM</p>
        <div class="li_cover">
          <h1>供应链管理平台</h1>
          <p>SUPPLY CHAIN MANAGEMENT PLATFORM</p>
          <p>淘钢网供应链平台实现跨环节的供应链整合，提高各环节间的协同效应。帮助企业内部集团与分公司、分公司与分公司、公司与部门、部门与部门等之间的信息高度协同。支持供应链上下游协同，如供应商、客户、物流中心、承运商等，提供立体化 的“网状”信息供应链高度协同平台。</p>
        </div>
      </li>
      <li>
        <h1>互联网采购平台</h1>
        <p>INTERNET PROCUREMENT PLATFORM</p>
        <div class="li_cover">
          <h1>互联网采购平台</h1>
          <p>INTERNET PROCUREMENT PLATFORM</p>
          <p>淘钢网数字化智慧电子采购平台，功能完备，体系规范，是高达精心构建出的阳光、规范、高效的满足三方架构集采交易平台。通过平台体系化建设，建立一系列规章制度，实现降成本、提高效率！实现集团采购阳光、规范、高效管理。适用于各种大型企业集团。</p>
        </div>
      </li>
      <li>
        <h1>互联网营销平台</h1>
        <p>INTERNET MARKETING PLATFORM</p>
        <div class="li_cover">
          <h1>互联网营销平台</h1>
          <p>INTERNET MARKETING PLATFORM</p>
          <p>“以客户为中心”建设互联网营销平台，实现业务协同·在线化、客户服务-移动化、交易单据-电子化，提高用户体验和业务扩展支撑能力，加强内外部信息协同并优化内部作业流程，提升组织的工作效率和服务质量，提升公司对市场的快速反应能力。</p>
        </div>
      </li>
    </ul>
    <div class="module_title wow animate__fadeInUp" data-wow-duration="2s" id="success">
      成功案例
      <div class="line wow animate__fadeInUp" data-wow-duration="2s"></div>
    </div>
    <div class="success_cases wow animate__fadeInUp" data-wow-duration="2s">
      <el-carousel :interval="5000" type='card' arrow="always">
        <el-carousel-item v-for="item in imgList" :key="item.img">
          <img :src="item.img" alt="">
          <div class="success_cases_content">
            <h1>{{item.name}}</h1>
            <p>{{item.desc}}</p>
            <!-- <div class="more">了解更多<span>></span></div> -->
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="module_title wow animate__fadeInUp" data-wow-duration="2s">
      合作生态
      <div class="line wow animate__fadeInUp" data-wow-duration="2s"></div>
    </div>
    <div class="ecology wow animate__fadeInUp" data-wow-duration="2s">
      <img src="../assets/images/home/ecology.png" alt="">
    </div>
    <div class="news_main">
      <div class="module_title wow animate__fadeInUp" data-wow-duration="2s">
        新闻资讯
      <div class="line wow animate__fadeInUp" data-wow-duration="2s"></div>
      </div>
      <div class="news_box wow animate__fadeInUp" data-wow-duration="2s">
        <el-carousel :interval="15000">
          <el-carousel-item v-for="(item,index) in listTemp" :key="index">
            <ul>
              <li v-for="(itemI,indexI) in item" :key="indexI" @click="goNewsDetail(itemI.id)">
                <div class="divimg">
                  <img v-if="itemI.image" :src="baseUrl + itemI.image" alt="">
                  <img v-else src="../assets/images/home/default.jpg" alt="">
                </div>
                <h1>{{itemI.title}}</h1>
                <p>{{itemI.description}}</p>
                <span>⇀</span>
              </li>
            </ul>
          </el-carousel-item>
        </el-carousel>
      </div>
      <div class="more_news wow animate__fadeInUp" data-wow-duration="2s" @click="goNews">更多新闻 ></div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { getNewsList} from '@/request/api'
import { baseUrl } from '@/request/baseUrl'
import HeaderView from '@/components/headerView.vue'
export default {
  name: 'Home',
  data(){
    return{
      // bgColor:'rgba(255,255,255,.4)',
      bgColor:'',
      newsList:[],
      imgList:[
        {img:require('../assets/images/home/bg.jpg'),name:'商丘数字化钢铁工业产业园',desc:'商丘钢铁物流产业园位于中国河南省商丘市，是上海建联中淘信息科技集团投资建设的钢铁物流加工配送市场、现代化仓储货场及钢构加工生产厂区。累计占地面积800多亩，投资规模6000万。是目前国内最先进的综合式钢铁物流配送中心之一。该项目旨在打造一个现代化的钢铁物流、智能加工综合园区，为钢铁行业提供全方位的服务和支持。'},
        {img:require('../assets/images/home/bg1.jpg'),name:'新郑数字化钢铁工业产业园',desc:'拥有先进的物流设施和智能化管理系统，为钢铁企业提供高效、安全的物流运作环境。园区配备现代化仓储货场，提供大规模钢铁储存和分拣能力，确保物流流程的畅通无阻。通过运用先进的信息科技，实现智能化管理和精确的数据分析，为企业提供精准的物流解决方案。园区内建有完善的配套设施，包括交通、食宿、商务、会议等，为企业提供便利的办公和商务环境。'},
        {img:require('../assets/images/home/bg2.jpg'),name:'长葛数字化钢铁工业产业园',desc:'长葛钢铁物流园位于长葛市新区京广铁路以东、京珠高速公路以西、南邻许昌县界，毗邻107国道。市场占地总面积1000余目，建房面积42000平方米，总投资8000万元，可容纳1000余家钢铁产业供应链企业入场经营，年交易额可达60亿元以上，该市场是以经营钢铁、加工、物流、仓储、供应链金融产品为主的大型现代化交易市场。'},
      ],
      pagination:{
        page:1,
        pageSize:100,
      },
    }
  },
  components: {
    HeaderView
  },
  created(){
    this.baseUrl = baseUrl
    this.getNewsList()
  },
  mounted(){
    new this.$wow.WOW().init({//新建实列
      boxClass: 'wow',//class名字
      // animateClass: 'animate__animated',//animateclass动画库类名,和当前animate动画库决定
      offset: 0,//到元素距离触发动画（当默认为0）
      mobile: true,//在移动设备上触发动画（默认为true）
      live: true//对异步加载的内容进行操作（默认为true）
    })
  },
  computed:{
    listTemp:function(){
      let index =0;
      let count = 3;
      let arrTemp = [];
      let experts = this.newsList;
      for(let i=0;i<this.newsList.length;i++){
        index = parseInt(i/count);
        if (arrTemp.length <= index) {
            arrTemp.push([]);
        }
        arrTemp[index].push(experts[i])
      }
      return arrTemp
    }
  },
  methods:{
    goNews(){
      this.$router.push('/news')
    },
    goNewsDetail(id){
      this.$router.push({path:'/newsDetail',query:{id:id}})
    },
    getNewsList(){
      getNewsList(this.pagination).then((res)=>{
        this.newsList = res.data.data
      })
    }
  }
}
</script>
<style scoped lang="less">
.container{
  // 出现滚动条时，背景图铺完
  min-width: max-content;
  min-width: -moz-max-content;
  .banner{
    width:1920px;
    height:500px;
    position: relative;
    background: url('../assets/images/home/banner.jpg')no-repeat;
    background-size:100% 100%;
    .banner_text{
      width:1200px;
      margin:0 auto;
      padding-top:170px;
      padding-left:80px;
      text-align:left;
      color: #333333;
      animation: banner-animation 1s ease-in-out;
    }
    h1{
      height: 40px;
      font-size: 40px;
      margin-bottom:20px;
    }
    p{
      font-size: 30px;
    }
  }
}

// 核心产品
.core_product{
  width:1200px;
  margin:0 auto;
  display:flex;
  flex-wrap:wrap;
  justify-content: space-between;
  //position: sticky;
  //animation: banner-animation 1s ease-in-out;
  li{
    height:261px;
    margin-bottom:10px;
    position:relative;
    h1{
      text-align:left;
      margin-bottom:7px;
      margin-top:50px;
      color:#205194;
      margin-left:50px;
    }
    p{
      text-align:left;
      color:#205194;
      margin-left:50px;
      font-size:12px;
    }
    &:nth-of-type(1){
      width:1200px;
      height:261px;
      background:url('../assets/images/home/product1.png')no-repeat;
      background-size:100% 100%;
      h1{font-size:40px;}
    }
    &:nth-of-type(2){
      width:595px;
      background:url('../assets/images/home/product2.png')no-repeat;
      background-size:100% 100%;
      h1{font-size:30px;}
    }
    &:nth-of-type(3){
      width:595px;
      background:url('../assets/images/home/product3.png')no-repeat;
      background-size:100% 100%;
      h1{font-size:30px;}
    }
    &:nth-of-type(4){
      width:595px;
      background:url('../assets/images/home/product4.png')no-repeat;
      background-size:100% 100%;
      h1{font-size:30px;}
    }
    &:nth-of-type(5){
      width:292px;
      background:url('../assets/images/home/product5.png')no-repeat;
      background-size:100% 100%;
      h1{font-size:20px;margin-top:30px;margin-left:30px;}
      p{margin-left:30px;}
    }
    &:nth-of-type(6){
      width:293px;
      background:url('../assets/images/home/product6.png')no-repeat;
      background-size:100% 100%;
      h1{font-size:20px;margin-top:30px;margin-left:30px;}
      p{margin-left:30px;}
    }
    overflow:hidden;
    .li_cover{
      width:100%;
      height:261px;
      background:rgba(45, 103, 195, 0.5);
      position:absolute;
      top:261px; 
      left:0;
      h1{
        color:#fff;
      }
      p{
        color:#fff;
      }
    }
    &:hover{
      h1{
        display:none;
      }
      p{
        display:none;
        &:nth-of-type(2){
          padding-right:20px;
          margin-top:12px;
          line-height:18px;
        }
      }
      .li_cover{
        animation: shake-out 0.7s ease-in-out;
        height:261px;
        display:block;
        top:0;
        h1{
          display:block;
        }
        p{
          display:block;
        }
      }
      @keyframes shake-out {
        0% {
            transform: translatey(261px);
        }
        100% {
            transform: translatey(0);
        }
      }
    }
  }
}
// 项目全景图
.productAll{
  text-align:center;
}
// 合作生态
.ecology{
  text-align:center;
}
// 成功案例
.success_cases{
  // width:1200px;
  height:452px;
  margin:0 auto;
  img{
    width:100%;
    height:100%;
  }
  /deep/.el-carousel__container{
    height:452px;
  }
  /deep/.el-carousel__item{
    height:100%!important;
  }
  .success_cases_content{
    width:80%;
    height:340px;
    margin:30px auto 0;
    background:rgba(0,0,0,.3);
    position:absolute;
    top:0;
    bottom:20px;
    left:0;
    right:0;
    margin:auto;
    padding:50px;
    box-sizing:border-box;
    color:#fff;
    h1{
      font-size:30px;
      margin-bottom:25px;
    }
    p{
      font-size:18px;
      line-height:30px;
    }
    .more{
      position:absolute;
      bottom:-40px;
      left:0px;
      background:red;
      font-size:12px;
      padding:5px;
      padding-right:20px;
      border-radius:4px;
      span{
        display:inline-block;
        background:#fff;
        padding:5px;
        position:absolute;
        border-radius:0 5px 5px 0;
        top:0;
        right:-2px;
        color:red;
      }
    }
  }
}
// 新闻资讯
.news_main{
  background:url('../assets/images/home/news_bg.png')no-repeat;
  background-size:100% 100%;
  padding:30px 0 60px;
}
.news_box{
  width:1200px;
  height:457px;
  margin:0 auto;
  /deep/.el-carousel__container{
    height:457px!important;
  }
  .el-carousel{
    height:457px!important;
  }
  ul{
    height:100%;
    display:flex;
    li{
      width:32%;
      height:100%;
      background:#fff;
      cursor: pointer;
      margin-right:14px;
      .divimg{
        width:384px;
        height:228px;
        margin-bottom:35px;
        position:relative;
        overflow:hidden;
        img{
          position:absolute;
          width:384px;
          height:228px;
          transition: all 0.6s; //设置动画执行的时间为0.6s
          cursor: pointer;
        }
      }
      &:hover{
        img{
          transform: scale(1.3); //设置图片按照比例放大1.3倍
          
        }
      }
      h1{
        font-size: 20px;
        height:72px;
        box-sizing: border-box;
        padding:10px 20px;
        font-family: MicrosoftYaHei;
        color: #333333;
      }
      p{
        font-size: 14px;
        font-family: MicrosoftYaHei;
        color: #656565;
        line-height:24px;
        height:36px;
        padding:10px 20px;
        display: -webkit-box;
        -webkit-box-orient:vertical;
        text-overflow: ellipsis;
        overflow : hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;//表明是2行文本显示省略号，换成3则表明是3行文本显示省略号
        -webkit-box-orient: vertical;
      }
      span{
        color:#E73921;
        font-size:36px;
        padding:10px 20px;
      }
    }
  }
}
.more_news{
  width: 161px;
  height: 40px;
  line-height:40px;
  text-align:center;
  background: #E73921;
  color:#fff;
  border-radius: 20px 20px 20px 20px;
  margin:40px auto 0;
  cursor: pointer;
}
.el-carousel--horizontal{
}
.el-carousel__item{
  padding:0px 20px;
}
</style>
